.navbar {
  z-index: 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: var(--yellow);
}

.brand-title {
  font-size: 1.5rem;
  margin: 0.5rem;
  font-family: "Blimey";
}

.navbar-links ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-links li a {
  padding: 1rem;
  display: block;
  font-size: 1.2rem;
}

.toggle-button {
  cursor: pointer;
  position: absolute;
  top: 0.7rem;
  right: 0.3rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 2.6rem;
  height: 2.4rem;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: var(--blue);
  border-radius: 10px;
}

@media screen and (max-width: 1100px) {
}

@media screen and (max-width: 600px) {
  .toggle-button {
    display: flex;
  }

  .navbar-links {
    display: none;
    width: 100%;
  }
  .navbar {
    flex-direction: column;
    align-items: center;
  }
  .navbar-links ul {
    flex-direction: column;
    width: 100%;
  }
  .navbar-links li {
    text-align: center;
  }
  .navbar-links li a {
    padding: 0.5rem 1rem;
  }
  .navbar-links.active {
    display: flex;
  }
  .bar.bar1 {
    transform: translateY(8px) rotate(-50deg);
  }
  .bar.bar2 {
    display: none;
  }
  .bar.bar3 {
    transform: translateY(-8px) rotate(50deg);
  }
  .bar {
    transition: all 0.3s ease;
  }
}
