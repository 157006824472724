.container-depoimento {
  width: 40%;
  height: auto;
  padding-bottom: 4rem;
}

.depoimento {
  height: 40%;
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
  display: flex;
  flex-direction: column;

  background: var(--blue);
}

.client-name {
  color: var(--pink);
  margin: 0;
  padding: 0;
}

.client-review {
  color: var(--white);
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1100px) {
  .container-depoimento {
    width: 60%;
  }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
  .container-depoimento {
    width: 90%;
  }
  .client-review {
    width: 90%;
  }
}
