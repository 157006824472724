#contato {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cards-container {
  margin-top: 2rem;
  width: 80vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  gap: 1.2rem;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1100px) {
  .cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
  .cards-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}
