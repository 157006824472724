#apresentacao-bg {
  width: 100vw;
  height: 60vh;
  overflow-x: hidden;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url("../../assets/images/background-apresentacao.png");
}

#apresentacao-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 4rem;
}

#apresentacao-items img {
  width: 95%;
}

#apresentacao-items div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
}

#carrosel-apres {
  height: 60%;
}

#carrosel-apres img {
  border-radius: 40px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1100px) {
  #apresentacao-items div {
    width: 35%;
  }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
  #apresentacao-items {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 5rem;
  }
  #apresentacao-items div {
    width: 70%;
  }
}
