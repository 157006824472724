@font-face {
  font-family: "SimplonBold";
  src: url(assets/fonts/SimplonBP-Bold.ttf) format("opentype");
}
@font-face {
  font-family: "Simplon";
  src: url(assets/fonts/SimplonBP-Medium.ttf) format("opentype");
}
@font-face {
  font-family: "SimplonLight";
  src: url(assets/fonts/SimplonBP-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Cako";
  src: url(assets/fonts/Cako\ Black.otf) format("opentype");
}
@font-face {
  font-family: "Blimey";
  src: url(assets/fonts/Blimey-TRIAL-BoldA.otf) format("opentype");
}

:root {
  --yellow: #fac800;
  --blue: #002d46;
  --pink: #e68782;
  --white: #ffffff;
  --color-hover: #c9a100;
  --transition: all 200ms ease;
}

a {
  color: var(--blue);
  height: 100%;
  padding: 10px;
  transition: var(--transition);
}

a:hover {
  color: var(--pink);
}

* {
  text-decoration: none;
  box-sizing: border-box;
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Simplon", "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* background-image: url("assets/images/bg-texture.png"); */
  margin: 0;
  padding: 0;
  background-color: var(--white);
}

section {
  margin-top: 5rem;
  width: 100vw;
}
.centralizar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
h3 {
  font-size: 1.5rem;
  color: var(--pink);
  font-family: "Cako", "Times New Roman", Times, serif;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1100px) {
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
}
