#sobre-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 60vh;
}

#sobre-container img {
  width: 100%;
  border-radius: 0.5rem;
}

#sobre-imagem {
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: url(/src/assets/images/sobre-img.jpg);
}
#sobre-imagem:nth-child(1) {
  width: 30rem;
  height: 25rem;
  background-position: center;
}

#sobre-conteudo {
  margin-top: 3rem;
  width: 75vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  align-items: flex-start;
  justify-items: end;
}

#sobre-texto {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: 80%;

  gap: 2rem;

  text-align: justify;
  font-family: "Simplon";
  color: var(--blue);
}
#sobre-texto p {
  font-size: 1.8rem;
}

#sobre-texto h1 {
  font-family: "Blimey";
  font-size: 2.2rem;
  color: var(--blue);
  margin: 0;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1440px) {
  #sobre-texto {
    justify-content: center;
    width: 90%;
  }
  #sobre-texto p {
    font-size: 1.4rem;
  }

  #sobre-texto h1 {
    font-size: 1.5rem;
  }
  #sobre-imagem:nth-child(1) {
    width: 30rem;
    height: 20rem;
    background-position: center;
  }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
  #sobre-conteudo {
    margin-top: 1rem;
    width: 90vw;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  #sobre-container {
    height: 80vh;
    justify-content: center;
  }
  #sobre-texto {
    width: 100%;
    height: 50%;
    margin-bottom: 2rem;
    gap: 0;
  }
  #sobre {
    margin-top: 0;
  }
  #sobre-texto p {
    font-size: 1.2rem;
  }

  #sobre-texto h1 {
    padding: 1rem;
    font-size: 1.5rem;
  }
  #sobre-container img {
    margin-top: 1.5rem;
  }
  #sobre-imagem:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 380px) {
  #sobre-container {
    height: 92vh;
  }
}
